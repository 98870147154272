.footer {
    background-color: #000000;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    height: 25px;
  }
  
  .footer__logo {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
  }
  
  .footer__menu {
    display: flex;
    list-style: none;
    margin-left: auto;
  }
  
  .footer__item {
    margin-right: 20px;
  }

  .footer__item p {
    color: white;
    font-weight: 550;

  }
  
  .footer__link {
    color: #333;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  .footer__link__img {
    padding-top: 0.5vh;
    width: 25px;
    height: 25px;
  }

  .footer__link__contact__img {
    width: 30px;
    height: 35px;
  }
  
  .footer__link:hover {
    color: #e87c0c;
  }
  
  @media (max-width: 500px) {

    .footer {
      background-color: #000000;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
     
      width: 100%;
      position: fixed;
      bottom: 0;
    }
    .footer__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto;
      padding: 0 20px;
      height: 15px;
    }
    
    .footer__logo {
      color: #333;
      font-size: 24px;
      font-weight: bold;
      text-decoration: none;
    }
    
    .footer__menu {
      display: flex;
      list-style: none;
      margin-left: auto;
    }
    
    .footer__item {
      margin-right: 20px;
      
    }
  
    .footer__item p {
      color: white;
      font-size: 0.75em;
      font-weight: 550;
    }
    
    .footer__link {
      color: #333;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      font-size: 1em;

    }
  
    .footer__link__img {
      padding-top: 0.5vh;
      width: 25px;
      height: 25px;
    }
  
    .footer__link__contact__img {
      width: 30px;
      height: 35px;
    }
    
    .footer__link:hover {
      color: #e87c0c;
    }
    
  }
.main-section__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  text-decoration: none;
  color: purple;
  background-color: #fff; /* Ensure background consistency */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional: adds subtle shadow for better separation */
}

.main-section__card-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns content in the center */
  align-items: center; /* Centers items horizontally */
}

.main-section__card-image {
  width: 100%; /* Make image responsive */
  height: auto;
  margin-bottom: 10px;
  border-radius: 20px;
}

.main-section__card h3, .main-section__card p {
  text-align: center; /* Centers text */
  margin: 5px 10px; /* Adds space and prevents text from touching edges */
}

.language-selector {
  position: absolute;
  right: 10px; /* Adjusted for better positioning */
  top: 10px;
}

.main-section__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media (max-width: 980px) {
  .main-section__cards {
    grid-template-columns: 1fr 1fr; /* Adjust grid for smaller screens */
  }

  .main-section__card {
    flex-direction: column; /* Stack elements vertically */
  }

  .main-section__card-image {
    width: 100%; /* Full width images */
    height: auto;
    border-radius: 20px;
  }
}

@media (min-width: 981px) {
  .main-section__cards {
    grid-template-columns: repeat(3, 1fr); /* More columns for wider screens */
  }
}

@media (min-width: 1200px) {
  .main-section__cards {
    padding: 0 15vw; /* Responsive padding */
  }
}

@media (min-width: 1400px) {
  .main-section__card-image {
    max-width: 300px; /* Set the maximum width of images */
    max-height: 200px; /* Set the maximum height of images */
    margin-left: auto;
    margin-right: auto; /* Centers the image horizontally */
    border-radius: 20px;
  }
}

.blog-navbar {
  background-color: #000000; /* Adjust color as necessary */
  background-image: url("../../../public/SwissMountainsCode3.png");
  background-position: 0% 42%;
  background-repeat: no-repeat;
  background-size: 100vw 25vh;
}

.navbar-link {
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  border-radius: 10px;
  padding: 5px;
}


.blog__navbar__link__img {
  padding-top: 0.5vh;
  width: 2.2vh;
  height: 2vh;
}

.blog__navbar__link__contact__img {
  width: 2.3vh;
  height: 2.5vh;
}

.blog--navbar--logo {
  color: #ffffff;
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
}

.blog__navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
  height: 80px;
}

.blog__navbar__menu {
  display: flex;
  list-style: none;
}

.blog__navbar__item {
  margin-right: 20px;
}


@media (min-width: 750px) {
  .blog-navbar {
    background-color: #000000; /* Adjust color as necessary */
    background-image: url("../../../public/SwissMountainsCode3.png");
    background-position: 0% 38%;
    background-repeat: no-repeat;
    background-size: 100vw 35vh;
    padding: 10px; /* add some padding */
  }

  .blog--navbar--logo {
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
  }
}

@media (min-width: 1000px) {
  .blog-navbar {
    background-color: #000000; /* Adjust color as necessary */
    background-image: url("../../../public/SwissMountainsCode3.png");
    background-position: 0% 36%;
    background-repeat: no-repeat;
    background-size: 100vw 50vh;
    padding: 10px; /* add some padding */
  }

  .blog--navbar--logo {
    color: #ffffff;
    font-size: 27px;
    font-weight: bold;
    text-decoration: none;
  }
}

@media (min-width: 1400px) {
  .blog-navbar {
    background-color: #000000; /* Adjust color as necessary */
    background-image: url("../../../public/SwissMountainsCode3.png");
    background-position: 0% 36%;
    background-repeat: no-repeat;
    background-size: 100vw 65vh;
    padding: 10px; /* add some padding */
  }


  .blog--navbar--logo {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
  } 
}

@media (min-width: 1750px) {
  .blog-navbar {
    background-color: #000000; /* Adjust color as necessary */
    background-image: url("../../../public/SwissMountainsCode3.png");
    background-position: 0% 36%;
    background-repeat: no-repeat;
    background-size: 100vw 85vh;
    padding: 10px; /* add some padding */
  }
}

.blog-navbar-a-tags {
  padding-top: 7.5px;
  display: flex;
  flex-direction: row;
}

.navbar__item a {
  color: #ffffff; /* white color */
  text-decoration: none; /* remove underline */
}

.blog-navbar-link {
  color: white;
  text-decoration: none; /* This removes the underline from the links */
}

.navbar-link:not(:last-child) {
  margin-right: 20px;
}

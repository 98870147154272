.banner {
  position: relative;
  background-image: url("../../../images/SwissMountainsCode2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 48%;
  width: 100vw;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.banner__buttons {
  position: absolute;
  right: 20px;
  bottom: 5px;
  display: flex;
  flex-direction: row;
  opacity: 0.85;
}

/* Rest of your CSS code */


@media (max-width: 800px) {
  .banner {
  background-position: 50% 45%;
  height: 45vw;
  }
}

@media (max-width: 600px) {
  .banner {
  background-position: 50% 25%;
  height: 50vw;
  }
}

@media (min-width: 1500px) {
  .banner {
      background-size: cover;
      background-repeat: no-repeat;
    height: 450px;
  }
}

  .banner__text {
    text-align: center;
    color: #fff;
  }
  
  .banner__title {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 155px;
  }
  
  .banner__subtitle {
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  .banner__button {
    background-color: #000000;
    border: 2px dotted white;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    padding: 7.5px 18px;
    border-radius: 5px;
  }
  
  
  
.navbar {
    background-color: #000000;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  
  .navbar__linkedin__img {
    padding-top: 0.5vh;
    width: 2.5vh;
    height: 2.5vh;
  }

  .navbar__twitter__img {
    padding-top: 0.5vh;
    width: 2.5vh;
    height: 2.5vh;
  }
  
  .navbar__link__contact__img {
    width: 2.5vh;
    height: 3vh;
    padding-top: 10%;
  }

  .navbar__logo {
    color: #ffffff;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
  }
  
  .navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    height: 80px;
  }
  
  .navbar__menu {
    display: flex;
    list-style: none;
  }
  
  .navbar__item {
    margin-right: 20px;
  }
  
  .navbar__link {
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar__link:hover {
    color: #e87c0c;
  }

  @media (min-width: 750px) {
    .navbar__logo {
        color: #ffffff;
        font-size: 23px;
        font-weight: bold;
        text-decoration: none;
      }

      .navbar__linkedin__img {
        padding-top: 0.5vh;
        width: 3vh;
        height: 3vh;
      }

      .navbar__twitter__img {
        padding-top: 0.5vh;
        width: 3vh;
        height: 3vh;
      }
      
      .navbar__link__contact__img {
        width: 3vh;
        height: 3.5vh;
      }
    
      .navbar__logo {
        color: #ffffff;
        font-size: 21px;
        font-weight: bold;
        text-decoration: none;
      }
  }

  @media (min-width: 1000px) {
    .navbar__logo {
        color: #ffffff;
        font-size: 27px;
        font-weight: bold;
        text-decoration: none;
      }

      .navbar__logo {
        color: #ffffff;
        font-size: 23px;
        font-weight: bold;
        text-decoration: none;
      }

      .navbar__linkedin__img {
        padding-top: 0.5vh;
        width: 3.5vh;
        height: 3.5vh;
      }
      
      .navbar__twitter__img {
        padding-top: 0.5vh;
        width: 3.5vh;
        height: 3.5vh;
      }

      .navbar__link__contact__img {
        width: 3.5vh;
        height: 4vh;
        padding-top: 5%;
      }
  }
  
  /* @media (min-width: 1400px) {
    .navbar__logo {
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
      }

      .navbar__logo {
        color: #ffffff;
        font-size: 25px;
        font-weight: bold;
        text-decoration: none;
      }

      .navbar__linkedin__img {
        padding-top: 0.5vh;
        width: 4vh;
        height: 4vh;
      }

      .navbar__twitter__img {
        padding-top: 0.5vh;
        width: 4vh;
        height: 4vh;
      }
      
      .navbar__link__contact__img {
        width: 4vh;
        height: 4.5vh;
        padding-top: 5%;
      }
  } */

  @media (min-width: 1750px) {
    .navbar__logo {
        color: #ffffff;
        font-size: 33px;
        font-weight: bold;
        text-decoration: none;
      }
  }
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align items to start */
  /* align-items: center; */
  height: 100vh; 
  padding: 0 20px; 
  background-color: white;
}

.h1-animated {
  padding: 0 5%;
}

.date-language-section {
  position: relative; /* Add this */
  padding: 0 10%;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #1E1E1E; /* Replace #desiredColor with the color you want */
}

.content-item-title-image {
  width: 40%;
  height: 40%;
  border-radius: 20px;
}

.content-item > ol {
  margin-top: 3.5vh;
  margin-bottom: 5.5vh;
}

.content-item ul > li {
  list-style: inside;
  padding: 0 6.5%;
  margin-bottom: 40px;
  font-family: Georgia, Times, serif;
  font-size: 20px;
  color: #3C484E ;
}


ol > li {
  list-style: inside;
  list-style-type: decimal;
  padding: 0 6.5%;
  margin-bottom: 40px;
  font-family: Georgia, Times, serif;
  font-size: 22px;
  color: #3C484E ;
}

.GB-Select {
  position: absolute; /* Add this */
  top: 55%; /* Adjust as needed. This will move the flag down */
  right: 10; /* Adjust as needed. This could move the flag horizontally */
  transform: translateY(-50%); /* This will center the element vertically */
  display: inline-flex;
  /* font-size: 2em; */
  padding-left: 5px;
  height: 20px;
  width: 35px;
}

.DE-Select {
  position: absolute; /* Add this */
  top: 55%; /* Adjust as needed. This will move the flag down */
  right: 10; /* Adjust as needed. This could move the flag horizontally */
  transform: translateY(-50%); /* This will center the element vertically */
  display: inline-flex;
  /* font-size: 2em; */
  height: 35px;
  width: 40px;
}

.content-item .date {
  display: inline-flex;
  padding: 0 15%;
  padding-right: 5%;
}

pre > code[class*="language-"] {
  font-size: 13px;  /* Adjust this value to your liking */
  color: white;
  font-family:'Courier New', Courier, monospace;
  
}

.content-item {
  padding-top: 2.5vw;
  width: 100%; /* make sure divs take up the full width */
  background-color: white;
  /* text-align: center; */
  font-size: 20px;
  
}

.content-item > img {
  display: block;
  max-width: 70%; 
  height: auto;
  margin: 0 auto; /* center the image */  
}

.content-item > h1,
.content-item > h2,
.content-item > h3 {
  padding: 0 10%;
  text-align: left;
  line-height: 1.5;
  margin-top: 3vh;
  margin-bottom: 3vh;

}

.content-item > p {
  font-family: Georgia, Times, serif;
  font-size: 20px;
  color: #3C484E ;
  padding: 0 10%;
  margin-bottom: 2.5vh;
}

.content-item > pre {
  padding: 0 25%;
  text-align: left; /* left-align the code snippet */
}

.code-block {
  width: 90%; /* Control the width of the code block */
  margin: 0 auto; /* Center the code block */
  background-color: white;
  padding: 0 10%;
}

.date-language-section > h3 {

}

.date {
  display: flex;
}

@media (max-width: 750px) {

  pre > code[class*="language-"] {
    font-size: 15px;  /* Adjust this value to your liking */
  }

  .h1-animated {
    padding: 0 5.5%;
    font-size: 1.5em;
  }

  pre > code[class*="language-"] {
    font-size: 14px;  /* Adjust this value to your liking */
  }



  .date-language-section {
    position: relative; /* Add this */
    padding: 0 5.5%;
  }

  .content-item .date {
    display: inline-flex;
    padding: 0 0%;
    padding-right: 5%;
  }
  
  .content-item {
    width: 100%; /* make sure divs take up the full width */
    background-color: white;
    /* text-align: center; */
  }
  
  .content-item > img {
    display: block;
    max-width: 70%; 
    /* height: 10%; */
    height: auto;
    margin: 0 auto; /* center the image */ 
    padding: 0 10%; 
  }
  
  .content-item > h1,
  .content-item > h2,
  .content-item > h3,
  .content-item > ul {
    padding: 0 5.5%;
    text-align: left;

  }

  .content-item ul > li {
    list-style: inside;
    padding: 0 .5%;
    margin-bottom: 15px;
    font-family: Georgia, Times, serif;
    font-size: 20px;
    color: #3C484E ;
  }

  ol > li {
    list-style-type: decimal;
    padding: 0 0%;
    margin-bottom: 15px;
    font-family: Georgia, Times, serif;
    font-size: 18px;
    color: #3C484E ;
  }

  .content-item > strong {
    padding: 0 5.5%;
    font-family: Georgia, Times, serif;
    font-size: 17.5px;
    color: #3C484E ;
  }

  .content-item > p {
    padding: 0 5.5%;
    font-family: Georgia, Times, serif;
    font-size: 17.5px;
    color: #3C484E ;
  }
  
  .content-item > pre {
    padding: 0 15%;
    text-align: left; /* left-align the code snippet */
  }
  
  .code-block {
    width: 80%; /* Control the width of the code block */
    margin: 0 auto; /* Center the code block */
    background-color: white;
  }
  
  .GB-Select {
    position: absolute; /* Add this */
    top: 55%; /* Adjust as needed. This will move the flag down */
    right: 10; /* Adjust as needed. This could move the flag horizontally */
    transform: translateY(-50%); /* This will center the element vertically */
    display: inline-flex;
    /* font-size: 2em; */
    padding-left: 9px;
    height: 25px;
    width: 50px;
  }
  
  .DE-Select {
    position: absolute; /* Add this */
    top: 55%; /* Adjust as needed. This will move the flag down */
    right: 10; /* Adjust as needed. This could move the flag horizontally */
    transform: translateY(-50%); /* This will center the element vertically */
    display: inline-flex;
    /* font-size: 2em; */
    /* padding-left: 5px; */
    height: 55px;
    width: 75px;
  }
  
  .date {
    display: flex;
  }
}

@media (min-width: 750px) {

  .content-item .date {
    display: inline-flex;
    padding: 0 0%;
    padding-right: 5%;
  }

  pre > code[class*="language-"] {
    font-size: 17px;  /* Adjust this value to your liking */
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #1E1E1E; 
    font-size: 19px;  /* Adjust this value to your liking */
    width: 90%;
  }
}


@media (min-width: 1600px) {

  .content-item .date {
    display: inline-flex;
    padding: 0 0%;
    padding-right: 5%;
  }

  pre > code[class*="language-"] {
    font-size: 18px;  /* Adjust this value to your liking */
  }

  .content-item {
    width: 100%; /* make sure divs take up the full width */
    background-color: white;
    text-align: left; 
  }

  .content-item ul > li {
    list-style: inside;
    padding: 0 13.5%;
    margin-bottom: 35px;
    font-family: Georgia, Times, serif;
    font-size: 22px;
    color: #3C484E ;
    text-align: left;
  }
  
  .content-item ol > li {
    list-style: inside;
    list-style-type: decimal;
    padding: 0 13.5%;
    margin-bottom: 35px;
    font-family: Georgia, Times, serif;
    font-size: 22px;
    color: #3C484E ;
  }
  
  .content-item > img {
    display: block;
    max-width: 65%; 
    /* height: 10%; */
    height: auto;
    margin: 0 auto; /* center the image */ 
    padding: 0 15%;
  }
  
  .content-item > h1,
  .content-item > h2,
  .content-item > h3,
  .content-item > p {
    padding: 0 15%;
    text-align: left;
   
  }
  
  .content-item > pre {
    padding: 0 15%;
    text-align: left; /* left-align the code snippet */
  }
  
  .code-block {
    width: 70%; /* Control the width of the code block */
    margin: 0 auto; /* Center the code block */
    background-color: white;
  }
  
  .date-language-section > h3 {
  
  }

  .date-language-section {
    position: relative; /* Add this */
    padding: 0 15%;
  }
  
  .date {
    display: flex;
  }
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  width: 60px;
  height: 34px;
  margin-bottom: 5vw;
  
}

.toggle-switch .uk {
  /* margin-right: 12vw; */
  margin-left: -6vw;
  margin-top: -1vw;
  font-size: 3em;
}

.toggle-switch .de {
  margin-left: 9vw;
  margin-top: -1vw;
  font-size: 3em;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 10px;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 10px;

}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
